// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// import "cocoon";
import 'cocoon-js';

import Sortable from 'sortablejs';
import fileUpload from 'fileUpload'
import smoothscroll from 'smoothscroll-polyfill';
import useSortable from 'sortable'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


// listen on 'turbolinks:load' instead of 'DOMContentLoaded' if using Turbolinks
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.upload-file').forEach(fileInput => {
    fileUpload(fileInput)
    document.querySelectorAll('.uppy-FileInput-btn').forEach(button => {
      button.classList.remove("uppy-FileInput-btn");
      button.classList.add("btn");
      button.classList.add("btn-primary");
    })
  })

  document.querySelectorAll('[data-sort="marker"]').forEach(list => {
    useSortable(list);
  })

  const updatePosition = async(url, data) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content

    console.log('Updating data');

    fetch(url, {
      method: 'PUT', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': csrfToken
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
      alert("Position update was not successful.")
    });
  }

  const table = document.getElementById("tripPartsTable")
  if (table) {
    const sortable = Sortable.create(table, {
      animation: 150,
      ghostClass: 'bg-primary',
      handle: '.handle',
      onUpdate: function (evt) {
        const url = evt.item.dataset.url;
        const newIndex = evt.newIndex;
        updatePosition(url, { "trip_part": { "position": newIndex } });
    	},
    });
  }

  const table2 = document.getElementById("safetyAdviceTable")
  if (table2) {
    const sortable = Sortable.create(table2, {
      animation: 150,
      ghostClass: 'bg-primary',
      handle: '.handle',
      onUpdate: function (evt) {
        const url = evt.item.dataset.url;
        const newIndex = evt.newIndex;
        updatePosition(url, { "safety_advice": { "position": newIndex } });
    	},
    });
  }
})


// kick off the polyfill!
smoothscroll.polyfill();
