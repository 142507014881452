import Sortable from 'sortablejs';

function useSortable(list) {
  // var sortable = Sortable.create(list, {
  //   handle: '.sortable-handle',
  //   ghostClass: 'ghost',
  //   onUpdate: function (evt) {
  //     const id = evt.item.dataset.id;
  //     const newIndex = evt.newIndex;
  //
  // 	},
  // });
}


export default useSortable;
