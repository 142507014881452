// app/javascript/fileUpload.js

import 'uppy/dist/uppy.min.css'

// import {
//   Core,
//   FileInput,
//   Informer,
//   ProgressBar,
//   ThumbnailGenerator,
//   XHRUpload,
// } from 'uppy'
//
// function fileUpload(fileInput) {
//   const hiddenInput = document.querySelector('.upload-data'),
//         imagePreview = document.querySelector('.upload-preview img'),
//         formGroup = fileInput.parentNode
//
//   // remove our file input in favour of Uppy's
//   formGroup.removeChild(fileInput)
//
//   const uppy = Core({
//     autoProceed: true,
//     restrictions: {
//       maxNumberOfFiles: 1,
//     },
//   })
//   .use(FileInput, {
//     target: formGroup,
//     locale: {
//       chooseFiles: 'Choose file'
//     }
//   })
//   .use(Informer, {
//     target: formGroup,
//   })
//   .use(ProgressBar, {
//     target: imagePreview.parentNode,
//   })
//   .use(ThumbnailGenerator, {
//     thumbnailHeight: 600,
//   })
//   .use(XHRUpload, {
//     endpoint: '/upload', // path to the upload endpoint
//   })
//
//   uppy.on('thumbnail:generated', (file, preview) => {
//     // show image preview while the file is being uploaded
//     imagePreview.src = preview
//   })
//
//   uppy.on('upload-success', (file, response) => {
//     // retrieve uploaded file data
//     const uploadedFileData = response.body['data']
//
//     // set hidden field value to the uploaded file data so that it's submitted
//     // with the form as the attachment
//     hiddenInput.value = JSON.stringify(uploadedFileData)
//   })
// }
//
// export default fileUpload
//




// import 'uppy/dist/uppy.min.css'

import {
  Core,
  FileInput,
  Informer,
  ProgressBar,
  ThumbnailGenerator,
  XHRUpload,
} from 'uppy'

function fileUpload(fileInput) {
  const fieldName = fileInput.name;
  const hiddenInput = document.querySelector('.upload-data[name="' + fieldName + '"]'),
        fieldPreview = document.querySelector('.upload-preview[data-upload="' + fieldName + '"]'),
        formGroup = fileInput.parentNode

  // remove our file input in favour of Uppy's
  formGroup.removeChild(fileInput)

  const uppy = Core({
      autoProceed: true,
      allowMultipleUploads: false,
    })
    .use(FileInput, {
      target: formGroup,
    })
    .use(Informer, {
      target: formGroup,
    })
    .use(ProgressBar, {
      target: fieldPreview.parentNode,
    })
    .use(ThumbnailGenerator, {
      thumbnailHeight: 600,
    })
    .use(XHRUpload, {
      endpoint: '/upload', // path to the upload endpoint
    })

  uppy.on('thumbnail:generated', (file, preview) => {
    // show image preview while the file is being uploaded
    fieldPreview.querySelector('img').src = preview
  })

  uppy.on('upload-success', (file, response) => {
    // retrieve uploaded file data
    const uploadedFileData = response.body['data']

    // <video src="<%= form.object.public_send("#{field}_url") %>" autoplay controls height="200" class="upload-preview-video"/>

    // set hidden field value to the uploaded file data so that it's submitted
    // with the form as the attachment
    hiddenInput.value = JSON.stringify(uploadedFileData)
    // // retrieve uploaded file data
    // const uploadedFileData = response.body['data']
    //
    if (file.type.split("/")[0] === 'video') {
      const uploadedFileUrl = response.uploadURL;
      fieldPreview.querySelector('video').src = uploadedFileUrl;
    }
    //
    // // set hidden field value to the uploaded file data so that it's submitted
    // // with the form as the attachment
    hiddenInput.value = JSON.stringify(uploadedFileData)
  })
}

export default fileUpload
